import request from '@/utils/request'

/**
 * 获取单页详情
 * @returns
 */
export async function singleDetail (id) {
    return request({
        url: '/api/web/single/' + id,
        method: 'get'
    });
}
/**
 * 获取文章列表
 * @returns
 */
export async function articleList (param, id) {
    return request({
        url: '/api/web/list/'+id,
        method: 'get',
        params: param
    });
}
/**
 * 获取字典数据
 * @returns
 */
export async function dictionaryList (param) {
    return request({
        url: '/api/data/',
        method: 'get',
        params: param
    });
}
/**
 * 获取文章详情
 * @returns
 */
export async function articleDetail (classId, id) {
    return request({
        url: `/api/web/data/${classId}/${id}`,
        method: 'get'
    });
}
/**
 * 获取分类
 * @returns
 */
export async function articleSection (param) {
    return request({
        url: '/api/news/classes',
        method: 'get',
        params: param
    });
}
/**
 * 全局搜索
 * @returns
 */
export async function globalSearch (param) {
    return request({
        url: '/api/web/search',
        method: 'get',
        params: param
    });
}
/**
 * 获取配置信息
 * @returns
 */
export async function configInfo (param) {
    return request({
        url: '/api/init',
        method: 'get',
        params: param
    });
}
/**
 * 获取友情链接
 * @returns
 */
export async function friendshipLinks (param) {
    return request({
        url: '/api/links/',
        method: 'get',
        params: param
    });
}